/**
 * @description - The pdf viewer component that is using content parsing into
 * @react-pdf/renderer module component
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

import { Page, View, Text, Image, Document } from '@react-pdf/renderer';
import { useState, useEffect } from 'react';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Styles
import pdfViewerStyle from './pdfViewerStyles';
// Types
import { TypeDocumentContent } from '@types';
import { parseDocumentTitles, parseDocumentContent } from 'utils/parseDocument';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The interface for the pdf viewer component. Contains pdf
 * document content type
 */
interface PdfViewerProps {
  documentContent: TypeDocumentContent[] | null;
  documentExternalData: any;
}

/**
 * @description - The view component for the pdf viewer
 * @param documentContent
 * @param documentExternalData
 * @constructor
 */
function PdfContentViewer({
  documentContent,
  documentExternalData
}: PdfViewerProps) {
  const [documentData, setDocumentData] = useState(null);

  useEffect(() => {
    if (documentExternalData && documentExternalData !== documentData) {
      setDocumentData(documentExternalData);
    }
  }, [documentExternalData]);
  /**
   * @description - The method is getting the document titles in a form of
   * Typography objects list
   * @param language,
   * @param data,
   */
  const getDocumentTitles = (
    language: 'am' | 'en' | 'ru',
    data: string[] | null
  ) => {
    const finalTitles = parseDocumentTitles(documentContent, language);
    if (finalTitles && finalTitles.length > 0 && data) {
      let listOfTitle: any = [];
      finalTitles.map((value, index) => {
        let finalValue = value.value;
        if (data) {
          const dataList = Object.values(data);
          value.value
            .split(' ')
            .filter((item) => item.includes('bisflow.value.'))
            .map((inputVariable) => {
              const valueIndex = parseInt(
                inputVariable.replace('bisflow.value.', '')
              );
              const inputValue = dataList[valueIndex];
              if (inputValue) {
                finalValue = finalValue.replace(inputVariable, inputValue);
              }
            });
        }
        listOfTitle.push(
          <Text key={`title_${language}_${index}`} style={pdfViewerStyle.h4}>
            {finalValue}
          </Text>
        );
      });
      return listOfTitle;
    }
    return <Text style={pdfViewerStyle.h4}>No Title</Text>;
  };

  /**
   * @description - The method is getting the document content in a form of
   * Typography objects list
   * @param language
   * * @param data,
   */
  const getDocumentContent = (language: 'am' | 'en' | 'ru', data: string[]) => {
    const finalContent = parseDocumentContent(documentContent, language);
    if (finalContent && finalContent.length > 0 && data) {
      return finalContent.map((value, index) => {
        let finalValue = value.value;
        const dataList = Object.values(data);
        value.value
          .split(' ')
          .filter((item) => item.includes('bisflow.value.'))
          .map((inputVariable) => {
            const valueIndex = parseInt(
              inputVariable.replace('bisflow.value.', '')
            );
            const inputValue = dataList[valueIndex];
            if (inputValue) {
              finalValue = finalValue.replace(inputVariable, inputValue);
            }
          });
        if (value.type === 'paragraph') {
          return (
            <Text key={`par_${language}_${index}`} style={pdfViewerStyle.body1}>
              {finalValue}
            </Text>
          );
        } else {
          return (
            <Text
              key={`par_${language}_${index}`}
              style={pdfViewerStyle.subtitle2}
            >
              {finalValue}
            </Text>
          );
        }
      });
    }
    return <Text style={pdfViewerStyle.body1}>No Content</Text>;
  };

  if (documentData) {
    return (
      <Document>
        <Page size="A4" style={pdfViewerStyle.page}>
          <View style={[pdfViewerStyle.gridContainer, pdfViewerStyle.mb40]}>
            <View style={pdfViewerStyle.col6Arm}>
              {/* @ts-ignore */}
              {getDocumentTitles('am', documentData?.fields?.am)}
              <Text style={pdfViewerStyle.body1}>ք. Երևան</Text>
              <Text style={pdfViewerStyle.body1}>կնքման վայր</Text>
              {/* @ts-ignore */}
              {getDocumentContent('am', documentData?.fields?.am)}
            </View>
            <View style={pdfViewerStyle.col6}>
              {/* @ts-ignore */}
              {getDocumentTitles('en', documentData?.fields?.en)}
              {/*<Text style={pdfViewerStyle.body1}>Yerevan</Text>*/}
              {/*<Text style={pdfViewerStyle.body1}>Place of signing</Text>*/}
              {/* @ts-ignore */}
              {getDocumentContent('en', documentData?.fields?.en)}
            </View>
          </View>
        </Page>
        <Page size="A4" style={pdfViewerStyle.page}>
          <View style={[pdfViewerStyle.gridContainer, pdfViewerStyle.mb40]}>
            <View style={pdfViewerStyle.col6Arm}>
              {/*<Text style={pdfViewerStyle.subtitle2}>*/}
              {/*  ԴԻԼԻՋԱՆ ԻՆՏԵՐՆԵՅՇՆԼ ՍՔՈՒԼ ՕՖ ԱՐՄԵՆԻԱ Հիմնադրամ*/}
              {/*</Text>*/}
              {/*<Text style={pdfViewerStyle.subtitle2}>ՀՎՀՀ՝ 00108599</Text>*/}
            </View>
            <View style={pdfViewerStyle.col6}>
              {/*<Text style={pdfViewerStyle.subtitle2}>*/}
              {/*  DILIJAN INTERNATIONAL SCHOOL OF ARMENIA Foundation*/}
              {/*</Text>*/}
              {/*<Text style={pdfViewerStyle.subtitle2}>TIN: 00108599</Text>*/}
            </View>
          </View>
          {/* @ts-ignore */}
          {documentData?.signature_side1 && (
            <View style={[pdfViewerStyle.gridContainer, pdfViewerStyle.mb40]}>
              <View style={pdfViewerStyle.col4}>
                <Image
                  // @ts-ignore
                  src={documentData.signature_side1}
                  style={{ height: 48 }}
                />
              </View>
            </View>
          )}
          <View style={[pdfViewerStyle.gridContainer, pdfViewerStyle.mb40]}>
            <View style={pdfViewerStyle.col12Arm}>
              <Text style={pdfViewerStyle.h4}>ԿԱՏԱՐՈՂ</Text>
            </View>
            <View style={pdfViewerStyle.col12Arm}>
              {
                // @ts-ignore
                documentData?.requisites?.am?.map((item, index) => (
                  <Text
                    key={`requisite_${item}_${index}`}
                    style={pdfViewerStyle.subtitle2}
                  >
                    {item}
                  </Text>
                ))
              }
            </View>
          </View>
          {/* @ts-ignore */}
          {documentData?.signature_side2 && (
            <View style={[pdfViewerStyle.gridContainer, pdfViewerStyle.mb40]}>
              <View style={pdfViewerStyle.col4}>
                <Image
                  // @ts-ignore
                  src={documentData.signature_side2}
                  style={{ height: 48 }}
                />
              </View>
            </View>
          )}
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      <Page size="A4" style={pdfViewerStyle.page} />
    </Document>
  );
}

export default PdfContentViewer;
