/**
 * @description - The method is parsing and displaying contracts list page &
 * managing to open any contract
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React
import { useCallback, useState } from 'react';
// Material UI's components
import { Button, Container, Dialog } from '@mui/material';
// Firebase
import { getStorage, ref, uploadBytes } from 'firebase/storage';
// Firestore
import {
  getFirestore,
  collection,
  doc,
  getDocFromCache,
  getDocs,
  query,
  where,
  setDoc
} from 'firebase/firestore';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Internal components
import { Page, ContractsListView, UploadSingleFile } from 'components';
// Hooks
import { useAuth } from 'hooks';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The contracts list view page
 * @constructor
 */
export default function ContractsPage() {
  // The state method for the current current selected file for upload
  const [selectedFile, setSelectedFile] = useState('');
  const [showDialog, setShowDialog] = useState('');
  // Create a root reference
  const toggleDialog = (dialogValue: string) => {
    setShowDialog(dialogValue);
  };
  const { user } = useAuth();
  const firebaseDb = getFirestore();
  const storage = getStorage();
  const [contractName, setContractName] = useState('');
  const [counterpartyName, setCounterpartyName] = useState('');
  const [counterpartyEmail, setCounterpartyEmail] = useState('');
  /**
   * @description - The method is listening to the callback of the state, and if
   * the user uploaded the file into the drop zone then it will trigger set
   * state method
   */
  const handleDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.path) {
      setSelectedFile({
        ...file,
        preview: URL.createObjectURL(file)
      });
      const date_time = Date.now();
      const pdfRef = ref(storage, `demo/${date_time.toString()}_${file.name}}`);
      // 'file' comes from the Blob or File API
      uploadBytes(pdfRef, file)
        .then((snapshot) => {
          console.log('Uploaded a blob or file!');
          console.log(snapshot);
          toggleDialog(snapshot?.metadata?.fullPath);
        })
        .catch((error) => console.log(error));
    }
  }, []);

  // Getting the current logged in user
  const createProject = () => {
    const documentRef = doc(collection(firebaseDb, 'temporaryDemo'));
    const emailRef = doc(collection(firebaseDb, 'mail'));
    setDoc(documentRef, {
      requisites: {
        am: [counterpartyName],
        en: [counterpartyName]
      },
      name: contractName,
      counterpartyEmail: counterpartyEmail,
      counterpartyName: counterpartyName,
      created: new Date(),
      filePath: showDialog,
      active: true,
      status: 'In Process',
      assigned: user?.id,
      signature_side2: '',
      side2: counterpartyEmail,
      id: documentRef.id
    }).then(() => {
      try {
        window.location.reload();
      } catch (e) {
        setShowDialog('');
      }
    });
    setDoc(emailRef, {
      created: new Date(),
      id: emailRef.id,
      to: [counterpartyEmail],
      cc: [user.email],
      message: {
        subject: `You have pending contract waiting for you signature`,
        text: `A contract have been created by ${user.email} and waiting for your signature. Please go to https://bisflow-demo.web.app/ in order to see the contract`
      }
    });
  };
  return (
    <Page title="Bisflow: Contracts">
      <Container>
        <Dialog
          open={showDialog.length > 0}
          onClose={() => toggleDialog('')}
          maxWidth="md"
        >
          <h3 style={{ margin: 10 }}>Please input contract info</h3>
          <input
            style={{ margin: 10, border: 0 }}
            type="text"
            placeholder={'Contract name'}
            onChange={(e: any) => setContractName(e?.target?.value)}
          />
          <input
            style={{ margin: 10, border: 0 }}
            type="text"
            placeholder={'Counterparty name'}
            onChange={(e: any) => setCounterpartyName(e?.target?.value)}
          />
          <input
            style={{ margin: 10, border: 0 }}
            type="email"
            placeholder={'Counterparty email'}
            onChange={(e: any) => setCounterpartyEmail(e?.target?.value)}
          />
          <Button sx={{ margin: 2 }} onClick={createProject}>
            Create
          </Button>
          <Button
            sx={{ margin: 2 }}
            onClick={() => setShowDialog('')}
            color={'error'}
          >
            Cancel
          </Button>
        </Dialog>
        <UploadSingleFile
          maxSize={209715200} // 25MB in bites
          accept="application/pdf" // Accepted only pdf format
          file={selectedFile}
          onDrop={handleDrop}
        />
        <ContractsListView />
      </Container>
    </Page>
  );
}
