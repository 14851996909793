/**
 * @description - The Drop Zone component, allowing to select or drop a file in
 * order to upload to server.
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Lodash - containing functionalities that allows to work with arrays, numbers,
// objects, strings, etc.
import { isString } from 'lodash';
// Material UI's
import { Box, Theme, Typography, Paper } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { SxProps } from '@mui/system';
// Drop Zone module, allowing to select and upload into server side.
import { useDropzone, DropzoneOptions } from 'react-dropzone';

// ========================================================================== //
// ================================= Imports ================================ //
// ========================================================================== //

// Local styles
import DropZoneStyle from './dropZoneStyle';
// Assets
import { UploadIllustration } from 'assets';
// Utils
import { fData } from '../../utils/formatNumber';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The custom file props interface.
 */
interface CustomFileProps extends File {
  path?: string;
  preview?: string;
}

/**
 * @description - The Drop Zone component's property interface.
 */
interface UploadSingleFileProps extends DropzoneOptions {
  error?: boolean;
  file: CustomFileProps | string | null;
  sx?: SxProps<Theme>;
}

/**
 * @description - The drop zone view that allows to upload single file
 */
export default function UploadSingleFile({
  error = false,
  file,
  sx,
  ...other
}: UploadSingleFileProps) {
  // Drop Zone hook for the external library drop zone
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections
  } = useDropzone({
    multiple: false,
    ...other
  });

  /**
   * @description - Internal view component that presents the rejected items.
   * @constructor
   */
  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size }: CustomFileProps = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  /**
   * @description - The drop zone view
   */
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          }),
          ...(file && { padding: '12% 0' })
        }}
      >
        <input {...getInputProps()} />

        <UploadIllustration sx={{ width: 220 }} />

        <Box sx={{ p: 3, ml: { md: 2 } }}>
          <Typography gutterBottom variant="h5">
            Drop or Select file
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Drop files here or click&nbsp;
            <Typography
              variant="body2"
              component="span"
              sx={{ color: 'primary.main', textDecoration: 'underline' }}
            >
              browse
            </Typography>
            &nbsp;thorough your machine
          </Typography>
        </Box>

        {file && <p>Successfully uploaded</p>}
        {/*//   <Box*/}
        {/*//     component="img"*/}
        {/*//     alt="file preview"*/}
        {/*//     src={isString(file) ? file : file.preview}*/}
        {/*//     sx={{*/}
        {/*//       top: 8,*/}
        {/*//       borderRadius: 1,*/}
        {/*//       objectFit: 'cover',*/}
        {/*//       position: 'absolute',*/}
        {/*//       width: 'calc(100% - 16px)',*/}
        {/*//       height: 'calc(100% - 16px)'*/}
        {/*//     }}*/}
        {/*//   />*/}
        {/*// )}*/}
      </DropZoneStyle>

      {fileRejections.length > 0 && <ShowRejectionItems />}
    </Box>
  );
}
